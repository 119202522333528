import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={34} height={34} viewBox="0 0 34 34" fill="none" {...props}>
      <path
        d="M16.857.949c-.087.165-.406.947-.724 1.74-.307.78-.604 1.485-.648 1.54-.043.067-.515.199-1.064.309-1.669.341-3.897 1.354-5.05 2.312l-.383.319.647.473.659.485.944-.584c1.767-1.123 3.622-1.64 5.872-1.64s4.105.517 5.883 1.64l.933.584.658-.474.659-.484-.384-.32c-1.175-.968-3.392-1.97-5.104-2.322l-1.02-.21-.736-1.794c-.395-.99-.78-1.816-.856-1.838-.066-.034-.198.088-.286.264zM6.639 6.718c-.088.132-.132.319-.099.418.044.099.505.726 1.043 1.41l.966 1.221.614-.605c.494-.507.582-.639.461-.749-.592-.55-2.458-1.927-2.623-1.927-.11 0-.274.11-.362.232zM25.88 7.378c-.626.496-1.208.958-1.285 1.035-.12.11-.033.242.461.749l.615.605.988-1.244c.976-1.233 1.152-1.607.856-1.905-.253-.253-.505-.132-1.636.76z"
        fill="#FFF"
      />
      <path
        d="M16.63 7.183c-.5.003-.949.03-1.254.085-3.853.683-7.058 3.743-7.98 7.596-.087.386-.197 1.266-.241 1.938l-.066 1.244h8.32l.285.308c.252.265.307.452.417 1.553.143 1.343.362 2.708.626 3.886.131.594.208.748.373.748s.241-.154.373-.737c.263-1.19.483-2.554.626-3.897.098-.98.186-1.322.362-1.553l.23-.297h3.216c3.677-.011 3.348-.143 2.975 1.2-.385 1.387-1.01 2.466-2.075 3.523-1.613 1.618-3.15 2.3-5.389 2.4-1.547.055-2.623-.176-4.05-.88-1.262-.617-2.71-2.049-3.347-3.303-.242-.474-.483-.86-.55-.86-.054 0-.438.133-.855.298-.703.275-.747.308-.68.573.109.484.91 1.794 1.536 2.532 1.075 1.277 2.228 2.124 3.863 2.818 1.153.495 2.305.715 3.765.715 1.954 0 3.523-.429 5.246-1.453.944-.55 2.832-2.499 3.392-3.5.845-1.509 1.35-3.48 1.35-5.241v-.705H18.745l-.263-.297c-.198-.242-.275-.484-.33-1.134-.087-1.035-.428-3.214-.669-4.271-.143-.617-.22-.793-.373-.793-.154 0-.23.176-.373.793-.242 1.046-.582 3.225-.67 4.271-.066.683-.132.881-.362 1.123l-.285.308h-3.161c-3.633 0-3.304.133-2.942-1.2a7.976 7.976 0 014.226-5.086c2.393-1.167 4.72-1.167 7.134.011 1.877.903 3.205 2.378 4.072 4.492.033.088 1.69-.606 1.69-.705 0-.616-1.383-2.74-2.436-3.743-1.317-1.255-2.59-1.981-4.281-2.477-.634-.181-1.989-.29-3.091-.283zm.546 9.358a.595.595 0 01.55.827.598.598 0 01-.55.37.595.595 0 01-.596-.598.599.599 0 01.596-.599z"
        fill="#FFF"
      />
      <path
        d="M6.826 9.426c-1.131 1.475-1.954 3.369-2.35 5.394l-.12.683-1.339.54c-.724.297-1.515.616-1.745.693-.516.176-.68.352-.494.517.077.055.922.419 1.866.793l1.712.683.132.737c.153.925.658 2.444 1.108 3.336.406.826 1.427 2.4 1.548 2.4.077 0 .966-1.145.966-1.244 0-.033-.264-.484-.593-1.002-1.076-1.717-1.602-3.644-1.602-5.846 0-2.334.647-4.503 1.887-6.308l.33-.484-.406-.595c-.231-.33-.45-.628-.505-.65-.044-.033-.22.133-.395.353zM26.636 9.536c-.208.286-.417.594-.46.66-.044.078.065.353.252.628 1.24 1.805 1.877 3.941 1.877 6.286 0 2.202-.527 4.129-1.603 5.846-.329.518-.592.969-.592 1.002 0 .099.889 1.244.966 1.244.12 0 1.14-1.574 1.547-2.4.45-.892.955-2.411 1.109-3.336l.131-.738 1.713-.682c.943-.374 1.788-.738 1.865-.793.187-.165.022-.341-.494-.517-.23-.077-1.02-.396-1.745-.694l-1.339-.54-.12-.682a13.336 13.336 0 00-1.921-4.778c-.735-1.123-.746-1.123-1.186-.506zM7.528 25.752c-.944 1.2-1.109 1.564-.823 1.85.263.264.57.11 1.81-.87l1.252-1.013-.57-.583c-.33-.33-.615-.595-.648-.595-.033 0-.494.55-1.021 1.211zM25.023 25.136l-.57.583 1.25 1.013c.692.55 1.361 1.002 1.482 1.002.285 0 .582-.408.483-.65-.099-.23-1.91-2.543-1.998-2.543-.033 0-.318.264-.647.595zM9.646 26.6l-.67.484.418.342c.636.506 1.778 1.156 2.743 1.563.89.374 2.514.837 2.964.837.33 0 .439.198 1.196 2.113.45 1.145.692 1.63.813 1.63.12 0 .362-.485.812-1.63.757-1.915.867-2.113 1.196-2.113.45 0 2.074-.463 2.963-.837.966-.407 2.108-1.057 2.744-1.563l.417-.342-.67-.484-.669-.495-.57.385c-.878.605-2.283 1.233-3.458 1.541-.933.253-1.284.287-2.765.287-1.482 0-1.833-.034-2.766-.287-1.175-.308-2.58-.936-3.458-1.541l-.57-.385-.67.495z"
        fill="#FFF"
      />
    </svg>
  );
}

export default SvgComponent;
