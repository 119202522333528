import React from 'react';

const PATHS = [
  'M44.36 33.296c2.285 1.254 2.285 4.537 0 5.791L25.868 49.244c-2.202 1.21-4.895-.383-4.895-2.896V26.033c-.001-2.512 2.692-4.106 4.894-2.896L44.36 33.296z',
  'M46.617 34.344c2.192 1.41 1.964 4.685-.402 5.778L23.912 50.424c-2.281 1.054-4.857-.724-4.68-3.232l1.66-23.587c.176-2.505 2.972-3.906 5.083-2.547l20.642 13.286z',
  'M49.129 36.306c2.06 1.596 1.549 4.837-.903 5.72l-26.97 9.72c-2.365.852-4.778-1.146-4.383-3.63l4.344-27.24c.395-2.48 3.3-3.63 5.285-2.093L49.13 36.306z',
  'M51.883 38.611c1.913 1.769 1.122 4.953-1.397 5.62l-32.283 8.557c-2.432.644-4.663-1.558-4.051-3.998l7.822-31.168c.611-2.434 3.605-3.325 5.448-1.622l24.46 22.611z',
  'M54.637 41.524c1.751 1.928.687 5.03-1.88 5.477l-38.184 6.646c-2.48.432-4.512-1.958-3.688-4.337L23.1 14.072c.821-2.37 3.88-2.997 5.567-1.14l25.97 28.592z',
  'M57.301 45.11c1.576 2.072.247 5.068-2.347 5.29L10.37 54.228c-2.51.215-4.328-2.344-3.298-4.643l17.613-39.302c1.026-2.288 4.128-2.644 5.646-.648l26.97 35.475z',
  'M60.096 49.248c1.388 2.202-.196 5.07-2.799 5.066L5.62 54.258c-2.52-.002-4.11-2.713-2.882-4.914L26.983 5.893c1.222-2.19 4.343-2.274 5.68-.153l27.433 43.508z',
];

function SvgComponent(props) {
  return (
    <svg
      width={65}
      height={60}
      fill="none"
      viewBox="0 0 65 60"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {PATHS.map((path) => (
        <path
          key={path}
          clipRule="evenodd"
          d={path}
          stroke="#4F5FFF"
          strokeWidth={0.688}
        />
      ))}
    </svg>
  );
}

export default SvgComponent;
