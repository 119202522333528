import React from 'react';

const SvgComponent = (props) => (
  <svg
    width={22}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 2.917a8.873 8.873 0 0 1-2.554.7A4.453 4.453 0 0 0 21.4 1.158c-.858.51-1.81.88-2.823 1.08a4.445 4.445 0 0 0-7.574 4.053A12.62 12.62 0 0 1 1.84 1.646c-.381.656-.6 1.42-.6 2.235 0 1.542.784 2.903 1.976 3.7a4.436 4.436 0 0 1-2.013-.556v.056a4.446 4.446 0 0 0 3.566 4.358 4.446 4.446 0 0 1-2.008.076 4.45 4.45 0 0 0 4.153 3.088 8.916 8.916 0 0 1-5.521 1.903c-.358 0-.713-.02-1.061-.062a12.584 12.584 0 0 0 6.814 1.997c8.177 0 12.647-6.774 12.647-12.648 0-.192-.004-.384-.013-.575a9.046 9.046 0 0 0 2.22-2.3Z"
    />
  </svg>
);

export default SvgComponent;
