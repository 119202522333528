import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 315.005 315.005"
    {...props}
  >
    <path d="M157.502 315.005c86.846 0 157.5-70.659 157.5-157.51C315.002 70.652 244.348 0 157.502 0S.002 70.652.002 157.495c0 86.851 70.655 157.51 157.5 157.51zm0-36.685c-24.949 0-48.641-7.47-68.91-21.669 8.266-13.713 33.391-48.075 87.209-68.604 13.566 36.779 20.475 68.164 23.279 82.886-13.275 4.905-27.232 7.387-41.578 7.387zm76.721-27.623c-3.045-14.877-9.189-41.054-19.854-71.896a166.877 166.877 0 0 1 16.516-.808c19.547 0 35.857 3.27 44.469 5.419-5.752 26.282-20.336 50.074-41.131 67.285zm22.141-162.019a121.078 121.078 0 0 1 21.311 58.722c-11.098-1.822-30.111-4.287-51.752-4.287-8.576 0-16.879.394-24.762 1.172-.207-.494-.416-.99-.627-1.488a413.88 413.88 0 0 0-5.271-11.792c32.155-14.424 51.64-32.09 61.101-42.327zm-98.862-51.61c27.08 0 53.256 9.115 74.467 25.806-7.313 8.176-23.978 23.742-53.309 35.865-14.623-26.151-29.629-48.322-38.209-60.465a120.875 120.875 0 0 1 17.051-1.206zM100.67 51.327c7.076 9.965 22.684 32.586 38.441 59.674-42.383 10.219-79.625 12.437-97.402 12.869 9.061-30.871 30.446-57.239 58.961-72.543zm56.547 93.158a442.726 442.726 0 0 1 3.949 8.194c-53.535 18.399-86.77 59.432-99.615 78.029-15.254-20-23.875-44.212-24.625-69.414 18.639-.195 68.336-2.21 120.291-16.809z" />
  </svg>
);

export default SvgComponent;
